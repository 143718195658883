import { ComponentRef, inject, Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { PaymentProgressIndicatorComponent } from '../components/payment-progress-indicator/payment-progress-indicator.component';

@Injectable({
  providedIn: 'root',
})
export class WaitingService {
  staticBrand = (window as any).SITEOWNER_BRAND;

  private _overlay = inject(Overlay);
  private _overlayRef: OverlayRef;
  private _componentRef: ComponentRef<PaymentProgressIndicatorComponent>;

  private initialize(withIndicator = false) {
    if (!this._overlayRef) {
      this._overlayRef = this._overlay.create({
        panelClass: 'overlay-full-screen',
      });
    }
    if (withIndicator && !this._componentRef) {
      const component = new ComponentPortal(PaymentProgressIndicatorComponent);
      this._componentRef = this._overlayRef.attach(component);
    }
  }

  public pending() {
    this.initialize(true);
    this._componentRef.setInput('state', 'pending');
  }

  public ready() {
    this.initialize(true);
    this._componentRef.setInput('state', 'ready');
  }

  public hide() {
    this.initialize();
    this._overlayRef.detach();
    if (this._componentRef) {
      this._componentRef.destroy();
      this._componentRef = null;
    }
  }
}
