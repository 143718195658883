import { fromEvent, Observable, of } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

export const VIEWPORT_ELEMENT_FALLBACK_CHECK_INTERVAL = 500;

export const isElementInViewport = function (element: Element, offset: number) {
  const rect = element.getBoundingClientRect();

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight || 0;
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth || 0;
  return (
    rect.top >= -offset &&
    rect.left >= -offset &&
    rect.bottom <= viewportHeight + offset &&
    rect.right <= viewportWidth + offset
  );
};

export const viewportVisibilityChange = function (element: HTMLElement, offset: number): Observable<boolean> {
  return new Observable((subscriber) => {
    let isVisible = null;
    if (window.IntersectionObserver) {
      const observer = new window.IntersectionObserver(
        (entries) => {
          const anyEntryIsIntersecting = entries.reduce((active, entry) => {
            return active || entry.isIntersecting;
          }, false);
          if (isVisible !== anyEntryIsIntersecting) {
            subscriber.next(anyEntryIsIntersecting);
            isVisible = anyEntryIsIntersecting;
          }
        },
        { rootMargin: offset + 'px' }
      );
      observer.observe(element);
      return () => {
        observer.disconnect();
      };
    }

    const checkInterval = setInterval(() => {
      const check = isElementInViewport(element, offset);
      if (isVisible !== check) {
        subscriber.next(check);
        isVisible = check;
      }
    }, VIEWPORT_ELEMENT_FALLBACK_CHECK_INTERVAL);

    return () => {
      clearInterval(checkInterval);
    };
  });
};
